import React from 'react';
import { Link } from 'react-router-dom';
import AProposPlombierImage1 from '../../assets/images/apropos-image-seo-1.jpeg';
import AProposPlombierImage2 from '../../assets/images/apropos-image-seo-2.jpeg';
import AProposPlombierImage3 from '../../assets/images/apropos-image-seo-3.jpeg';
import AProposSerrurierImage1 from '../../assets/images/apropos-image-serrurier-seo-1.jpeg';
import AProposSerrurierImage2 from '../../assets/images/apropos-image-seo-serrurier-2.jpeg';
import AProposSerrurierImage3 from '../../assets/images/apropos-image-seo-serrurier-3.jpeg';
import IconPlombier from '../../assets/images/plombier-service.svg';
import IconSerrurier from '../../assets/images/serrurier-service.svg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import PricingGeoImage from '../../assets/images/carte-agglomeration-montpellier.jpeg';
import PlombierPricingImage from '../../assets/images/urgence_plomberie_pricing_image.jpeg';
import SerrurierPricingImage from '../../assets/images/urgence_serrurerie_pricing_image.jpeg';

const APropos = () => {
    return (
        <div className="app-page-component">
            <div className="container-fluid app-page-cover-container">
                <div className="app-page-cover-content-body">
                    <div className="app-page-cover">
                        <h1 className="app-page-title">Urgence plombier et serrurier Montpellier pas cher</h1>
                        <div className="app-page-fil-ariane-container">
                            <Link to="/" className="app-page-fil-ariane-link">Accueil</Link>
                            &nbsp;&#10148;&nbsp;
                            <Link to="/a-propos-de-nous" className="app-page-fil-ariane-link active-page">A propos de nous</Link>
                        </div>
                    </div>
                </div>
            </div>
            <section className="service-apropos-section">
                <div className="container service-apropos-section-container">
                    <div className="service-apropos-section-body">
                        <h2 className="service-apropos-section-title">A Propos de nous</h2>
                        <div className="row service-apropos-row">
                            <div className="col-lg-12 service-apropos-col">
                                <div className="service-apropos-description-container">
                                    <div className="service-apropos-textual-body">
                                        <p>
                                            Bienvenue chez Urgence Plombier et Serrurier Montpellier pas cher ! Nous sommes une entreprise spécialisée dans les services d'urgence en plomberie et serrurerie à des tarifs abordables dans la région de Montpellier. Nous comprenons à quel point il est important d'avoir accès à des professionnels fiables et réactifs lorsque vous êtes confronté à des problèmes imprévus.
                                        </p>
                                        <p>
                                            Notre équipe de plombiers et de serruriers expérimentés est disponible 24 heures sur 24, 7 jours sur 7 pour répondre à vos besoins en cas d'urgence. Que ce soit une fuite d'eau, un robinet qui fuit, une canalisation bouchée ou une serrure endommagée, nous sommes là pour vous aider rapidement et efficacement.
                                        </p>
                                        <div className="row apropos-image-row">
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposPlombierImage1} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposPlombierImage2} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposPlombierImage3} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                        </div>
                                        <div className="row cta-row">
                                            <div className="col-lg-6 cta-col">
                                                <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                    <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                    <span className="phone-number">06 46 21 75 92</span>
                                                </Button>
                                            </div>
                                            <div className="col-lg-6 cta-col">
                                                <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                    <MdMarkEmailRead className="app-header-phone-icon" />
                                                    <span className="phone-number">Demandez un devis</span>
                                                </Button>
                                            </div>
                                        </div>
                                        <p>
                                            Nous nous engageons à fournir un service de qualité supérieure en utilisant des équipements et des techniques de pointe pour résoudre vos problèmes de manière durable. Notre priorité est de vous offrir des solutions adaptées à vos besoins, tout en respectant votre budget. C'est pourquoi nous proposons des tarifs compétitifs pour nos services d'urgence, sans compromettre la qualité de notre travail.
                                        </p>
                                        <p>
                                            En choisissant Urgence Plombier et Serrurier Montpellier pas cher, vous bénéficierez d'un service professionnel, ponctuel et courtois. Nous comprenons l'importance de votre satisfaction et nous nous efforçons de dépasser vos attentes à chaque intervention. Notre équipe est formée pour traiter rapidement les situations d'urgence, en minimisant les dommages potentiels et en vous offrant la tranquillité d'esprit que vous méritez.
                                        </p>
                                        <div className="row apropos-image-row">
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposSerrurierImage1} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposSerrurierImage2} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                            <div className="col-lg-4 apropos-image-col">
                                                <img src={AProposSerrurierImage3} alt="Urgence Plombier Montpellier pas cher" className="apropos-image-seo" />
                                            </div>
                                        </div>
                                        <div className="row cta-row">
                                            <div className="col-lg-6 cta-col">
                                                <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                    <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                    <span className="phone-number">06 46 21 75 92</span>
                                                </Button>
                                            </div>
                                            <div className="col-lg-6 cta-col">
                                                <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                    <MdMarkEmailRead className="app-header-phone-icon" />
                                                    <span className="phone-number">Demandez un devis</span>
                                                </Button>
                                            </div>
                                        </div>
                                        <p>
                                            Faites confiance à notre expertise en matière de plomberie et de serrurerie d'urgence à Montpellier. Contactez-nous dès maintenant pour obtenir une assistance rapide et efficace à des tarifs abordables. Nous sommes là pour vous aider, 24/7.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="apropos-page-nos-services">
                <div className="app-services-container container">
                    <h2 className="service-apropos-section-title">Nos service d'urgence plombier et serrurier Montpellier pas cher</h2>
                    <div className="app-service-item-row row">
                        <div className="app-service-card-container col-lg-6">
                            <div className="app-service-card">
                                <img src={ IconSerrurier} alt="Urgence Plombier Montpellier pas cher" className="app-service-card-image" />
                                <h2 className="app-service-card-title">Urgence Plombier Montpellier</h2>
                                <div className="app-service-card-textual-body">
                                    <p>En cas d'urgence plomberie à Montpellier, il est essentiel de pouvoir compter sur les services d'un plombier professionnel et fiable.</p>
                                    <p>Urgence Plombier Montpellier met à votre disposition une équipe d'experts est prête à intervenir 24 heures sur 24, 7 jours sur 7, pour résoudre tous vos problèmes de plomberie et de serrurerie : 
                                        <ul className="service-in-list">
                                            <li><strong>Plomberie Générale</strong></li>
                                            <li><strong>Réparation de fuites</strong></li>
                                            <li><strong>Débouchage de canalisations</strong></li>
                                            <li><strong>Installation de sanitaires</strong></li>
                                            <li><strong>Dégorgement WC</strong></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="app-service-card-container col-lg-6">
                            <div className="app-service-card">
                                <img src={IconPlombier} alt="Urgence Serrurier Montpellier pas cher" className="app-service-card-image" />
                                <h2 className="app-service-card-title">Urgence Serrurier Montpellier</h2>
                                <div className="app-service-card-textual-body">
                                    <p>Les problèmes de serrurerie peuvent survenir à tout moment, que ce soit une clé perdue, une serrure endommagée ou une porte claquée.</p>
                                    <p>Une situation pareille nécessite de faire appel à un serrurier qualifié qui puisse intervenir rapidement pour résoudre le problème de : 
                                        <ul className="service-in-list">
                                            <li><strong>Dépannage d'urgence</strong></li>
                                            <li><strong>Ouverture de portes fermées</strong></li>
                                            <li><strong>Changement de serrures</strong></li>
                                            <li><strong>Installation de portes blindées</strong></li>
                                            <li><strong>Remplacement de cylindre</strong></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section id="pricing-section">
                <div className="container pricing-section-container">
                    <div className="pricing-section-body">
                        <h4 className="pricing-section-title">Tarifs compétitifs pour les services d'urgence plomberie et serrurerie à Montpellier</h4>
                        <div className="row pricing-row">
                            <div className="col-lg-4 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix plombiers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement WC</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Dégorgement Furet</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>80 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Main d'oeuvre</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 image-pricing-table-col">
                                <div className="pricing-table-container">
                                    <img src={PlombierPricingImage} alt="Plombier Montpellier pas cher" className="pricing-geo-image" />
                                    <img src={PricingGeoImage} alt="Plombier et Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                    <img src={SerrurierPricingImage} alt="Serrurier Montpellier pas cher" className="pricing-geo-image" />
                                </div>
                            </div>
                            <div className="col-lg-4 pricing-table-col">
                                <div className="pricing-table-container">
                                    <h5 className="pricing-item-title">Nos prix serruriers</h5>
                                    <div className="pricing-items-container">
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes fermées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>50 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Ouverture de portes blindées</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>100 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Changement de serrure</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>90 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-item-card">
                                            <h6 className="pricing-item-card--title">Déplacement Montpellier et environs</h6>
                                            <div className="pricing-item-card-body">
                                                <div className="pricing-item">
                                                    <span>40 &euro;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row cta-row">
                                        <div className="col-lg-6 cta-col">
                                            <Button href="tel:0646217592" variant="default" className="app-cta-btn cta-phone-btn">
                                                <BsFillPhoneVibrateFill className="app-header-phone-icon" />
                                                <span className="phone-number">06 46 21 75 92</span>
                                            </Button>
                                        </div>
                                        <div className="col-lg-6 cta-col">
                                            <Button href="mailto:contact@intervention-plombier-serrurier24h.fr" variant="default" className="app-cta-btn cta-email-btn">
                                                <MdMarkEmailRead className="app-header-phone-icon" />
                                                <span className="phone-number">Demandez un devis</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default APropos