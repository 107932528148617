import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import AppLogo from '../../../assets/images/logo_psm.svg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    return (
        <header className="app-header-component">
            <div className="app-header-first-level-container">
                <div className="app-header-first-level-body">
                    <a href="tel:06 46 21 75 92" className="app-header-first-level phone-link-container">
                        <BsFillPhoneVibrateFill />
                        <span>06 46 21 75 92</span>
                    </a>
                    <a href="mailto:contact@intervention-plombier-serrurier24h.fr" className="app-header-first-level email-link-container">
                        <MdMarkEmailRead />
                        <span>nous écrire</span>
                    </a>
                </div>
            </div>
            <Navbar expand="lg" className="bg-body-tertiary app-header-navbar">
                <Container className="app-header-container">
                    <div className="navbar-toggle-container">
                        <Navbar.Toggle aria-controls="app-default-navbar-nav" />
                        <img src={AppLogo} alt="Urgence Plombier et Serrurier Montpellier pas cher" className="mobile-app-header-logo mobile" />
                    </div>
                    <Navbar.Collapse id="app-default-navbar-nav">
                        <Nav className="me-auto app-header-navigation-body" activeKey={location.pathname}>
                            <div className="app-header-nav-content">
                                <div className="app-header-section-col left-header-col">
                                    <div className="app-header-menu-navbar-body">
                                        <Nav.Link className="app-menu-item" href="/">Accueil</Nav.Link>
                                        <Nav.Link className="app-menu-item" href="/plombier-montpellier-pas-cher">Plombier</Nav.Link>
                                        <Nav.Link className="app-menu-item" href="/serrurier-montpellier-pas-cher">Serrurier</Nav.Link>
                                    </div>
                                </div>
                                <Nav.Link className="app-logo-link">
                                    <img src={AppLogo} alt="Urgence Plombier et Serrurier Montpellier pas cher" className="app-header-logo desktop" />
                                </Nav.Link>
                                <div className="app-header-section-col right-header-col">
                                    <div className="app-header-menu-navbar-body">
                                        <Nav.Link className="app-menu-item" href="#pricing-section">Nos Prix</Nav.Link>
                                        <Nav.Link className="app-menu-item" href="/a-propos-de-nous">A Propos</Nav.Link>
                                    </div>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header