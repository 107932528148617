import { Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './components/Homepage/Homepage';
import Header from './components/Shared/Header/Header';
import Footer from './components/Shared/Footer/Footer';
import GoogleReviewsBadge from './assets/images/badge-google.svg';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';
import Plombier from './components/Plombier/Plombier';
import Serrurier from './components/Serrurier/Serrurier';
import APropos from './components/APropos/APropos';
import { useEffect } from 'react';
import CookieConsent from "react-cookie-consent";

function App() {
  let location = useLocation();

  useEffect(() => {
    if(!location?.hash.startsWith('#app-page-service')){
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    console.log('location?.pathname', location)
  }, [location]);

  return (
    <div className="App-Router-Dom">
      <div className="cookies-consent-container">
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            cookieName="UrgencePlombierSerrurierMontpellierCookies"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            enableDeclineButton
            declineButtonText="Je refuse"
          >
            <div style={{fontSize:"10px"}} className="consent-message-container">
              Afin d’assurer le bon fonctionnement du site, de mesurer sa fréquentation, d'optimiser l’expérience utilisateur, nous vous informons que nous sommes susceptibles d’utiliser des cookies. Vous êtes libre à vous d'accepter, de refuser et de changer d'avis à tout moment.
            </div>
          </CookieConsent>
      </div>
      <Header />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/plombier-montpellier-pas-cher' element={ <Plombier /> } />
        <Route path='/serrurier-montpellier-pas-cher' element={ <Serrurier /> } />
        <Route path='/a-propos-de-nous' element={ <APropos /> } />
      </Routes>
      <Footer />
      <img src={GoogleReviewsBadge} alt="Plombier et Serrurier pas cher à Montpellier 7j/7 24H/24" className="google-badge-image linkable" onClick={() => window.open('https://g.co/kgs/3K6TpQ', '_blank')} />
      <div className="global-cta-btn-container">
        <a title="Appelez-nous" target="_blank" rel="noreferrer" href="tel:0646217592" className="global-cta-btn call-btn">
          <BsFillPhoneVibrateFill className="app-global-phone-icon" />
        </a>
        <a title="Ecrivez-nous" target="_blank" rel="noreferrer" href="mailto:contact@intervention-plombier-serrurier24h.fr" className="global-cta-btn quotation-btn">
          <MdMarkEmailRead className="app-global-phone-icon" />
        </a>
      </div>
    </div>
  );
}

export default App;
